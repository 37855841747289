import React from "react"
import { hasUserProfileKey, isBrowser } from "../../utils"
import { navigate } from "gatsby"
import { REGISTER, WORKOUTS_HOME } from "./Locations"
import useAuthenticatedRoute from "./useAuthenticatedRoute"
import BackdropLoader from "../../components/BackdropLoader"
import Layout from "../../components/layout"

export const BrowserOnlyRoute = ({ component: Component, location, ...rest }) => {
  if (isBrowser) {
    return null
  }
  return <Component {...rest} />
}

export const AuthenticatedOrSSRRoute = ({ component: Component, ...rest }) => {
  useAuthenticatedRoute(true, getRelativePathWithQuery())

  return <Layout>
    <Component {...rest} />
  </Layout>

}

export const AuthenticatedRoute = ({ component: Component, showLoader, ...rest }) => {
  const loading = useAuthenticatedRoute(false, getRelativePathWithQuery())
  if (!isBrowser)
    return null
  if (!hasUserProfileKey()) {
    navigate(REGISTER)
  }

  if (showLoader && loading) {
    return <BackdropLoader show={true}/>
  }

  return <Component {...rest} />
}

export const AnonymousOnlyRoute = ({ component: Component, ...rest }) => {
  if (isBrowser && hasUserProfileKey()) {
    navigate(WORKOUTS_HOME)
    return null
  }
  return <Component {...rest} />
}

function getRelativePathWithQuery() {
  return typeof window !== "undefined" ? window.location.pathname + window.location.search + window.location.hash : null
}
