import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress } from "@material-ui/core"
import Backdrop from "@material-ui/core/Backdrop"
import React from "react"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
}))

export interface Props {
  show: boolean
}

export default function BackdropLoader(props: Props) {
  const classes = useStyles()

  return (
    <Backdrop className={classes.backdrop} open={props.show}>
      <CircularProgress/>
    </Backdrop>
  )

}