import { useEffect, useState } from "react"
import firebase from "firebase"
import { navigate } from "gatsby"
import { clearUserProfile, hasUserProfileKey, isBrowser } from "../../utils"
import { LOGIN } from "./Locations"
import { updateRedirectSuccess } from "./routeSlice"
import { useDispatch } from "react-redux"
import { addHausmateCookie, removeHausmateCookie } from "../login/loginSaga"
import { logUserId } from "../analytics/analyticsSaga"


export default function useAuthenticatedRoute(ssrAllowed: boolean = false, path: string) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    if (isBrowser) {
      const unsubscribe = firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          setLoading(false)
          unsubscribe()
          addHausmateCookie()
          logUserId()
        } else if (!user) {
          dispatch(updateRedirectSuccess(path))
          console.error(
            "Access to protected route denied from not user, redirecting to login..."
          )

          // if a person is not authenticated, clear their profile
          if (hasUserProfileKey()) {
            clearUserProfile()
          }
          removeHausmateCookie()
          navigate(LOGIN)
        }
      })
    } else if (!ssrAllowed) {
      console.error(
        "Access to protected route denied from ssr, redirecting to login..."
      )
      navigate(LOGIN)
    }
  }, [])

  return loading
}